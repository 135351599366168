:root {
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Segoe UI', system-ui, Roboto, sans-serif;
}

.pt-serif-regular {
  font-family: "PT Serif", "Times New Roman", Georgia, Baskerville, Palatino, serif;
  font-weight: 400;
  font-style: normal;
}

.pt-serif-bold {
  font-family: "PT Serif", "Times New Roman", Georgia, Baskerville, Palatino, serif;
  font-weight: 700;
  font-style: normal;
}

.pt-serif-regular-italic {
  font-family: "PT Serif", "Times New Roman", Georgia, Baskerville, Palatino, serif;
  font-weight: 400;
  font-style: italic;
}

.pt-serif-bold-italic {
  font-family: "PT Serif", "Times New Roman", Georgia, Baskerville, Palatino, serif;
  font-weight: 700;
  font-style: italic;
}

.case-study-overlay {
  transition: transform 0.5s, opacity 0.5s;
  animation: slideUpFadeIn 0.5s ease-out forwards;
  opacity: 0; /* Ensure elements start hidden */
  display: flex;
  position: relative;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  padding-top: 120px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Update gradient background class */
.case-study.gradient-bg {
  background-color: #EFEBE0;
  position: relative;
  overflow: hidden;
}

.case-study.gradient-bg::before,
.case-study.gradient-bg::after {
  content: '';
  position: absolute;
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  filter: blur(120px);
  opacity: 0.4;
  z-index: 0;
  pointer-events: none;
}

.case-study.gradient-bg::before {
  background: radial-gradient(circle at center, rgba(255, 165, 0, 0.8), transparent 70%);
  top: -400px;
  left: -400px;
  animation: float 30s ease-in-out infinite;
}

.case-study.gradient-bg::after {
  background: radial-gradient(circle at center, rgba(128, 0, 128, 0.8), transparent 70%);
  bottom: -400px;
  right: -400px;
  animation: float 35s ease-in-out infinite reverse;
}

@keyframes float {
  0%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(150px, 150px) rotate(180deg);
  }
}

/* Ensure content stays above the gradient */
.case-study.gradient-bg .logo-container,
.case-study.gradient-bg .content-blocks {
  position: relative;
  z-index: 1;
}

.logo-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  box-sizing: border-box;
  overflow: visible;
  perspective: 1000px;
  transform-style: preserve-3d;
  will-change: transform;
  background-color: inherit;
  z-index: 2;
  margin-bottom: 2rem;
}

.case-study {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  padding: 0;
  perspective: 1px;
  will-change: transform;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Segoe UI', system-ui, Roboto, sans-serif;
}

.case-study h1 {
  font-size: clamp(48px, 8vw, 110px);
  margin: 1rem 0;
  line-height: 1.1;
  color: inherit;
  /* The font-family will be set dynamically based on the JSON textFont property */
}

.content-blocks h1,
.content-blocks h2,
.content-blocks h3,
.content-blocks p,
.content-block,
.element-text,
.element-image-text,
.element-video-text {
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Segoe UI', system-ui, Roboto, sans-serif;
}

.content-blocks h1 {
  font-size: 64px;
  margin-top: 200px;
}

.case-study h3 {
  font-size: 32px;
  margin-top: 21px;
  text-align: right;
  width: 60%;
  font-weight: 500;
}

.skills {
  margin-bottom: 20px;
}

.skill {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.content-blocks {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  transform-style: preserve-3d;
  will-change: transform;
  position: relative;
  z-index: 2;
}

.content-blocks.hidden {
  display: none;
}

.content-blocks h2 {
  font-weight: 800;
  opacity: 0.8;
}

.content-block {
  width: 100%;
  margin-bottom: 120px;
  opacity: 1;
  transform: none;
  transition: none;
  will-change: transform, opacity;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  backface-visibility: hidden;
  z-index: 2;
}

.content-block.visible {
  opacity: 1;
  transform: translateY(0);
}

.content-block::after {
  content: '';
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  background: linear-gradient(45deg, transparent 40%, rgba(255, 255, 255, 0.1) 50%, transparent 60%);
  border-radius: 50%;
  opacity: 0.5;
}

.case-study h2 {
  color: inherit;
  margin: 0;
  font-weight: 400;
}

.logo-container h2 {
  font-size: 1.2em;
  margin-top: 10px;
  margin-bottom: 20px;
}

.content-block h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  font-weight: 600;
}

.logo-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 2rem;
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  animation: fadeInAndUp 0.8s ease forwards;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.logo-pic:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(229, 86, 8, 0.3);
}

.logo-container h1 {
  font-size: 120px;
  margin-bottom: 1rem;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 0.8s;
  max-width: 100%;
  text-align: center;
  line-height: 1.2;
  width: 100%;
}

.logo-container h2 {
  font-size: 26px;
  font-weight: 200;
  margin-bottom: 2rem;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 1.3s;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 1.5;
  width: 100%;
  font-family: 'SF Pro Text', sans-serif;
}

.element-text {
  width: 100%;
  max-width: 800px;
  margin: 24px auto;
  text-align: left;
}

.element-text h3 {
  width: 100%;
  text-align: left;
  margin: 24px 0;
  font-size: 24px;
}

.element-text p,
.element-image p {
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
}

.element-video-text,
.element-image-text {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
  gap: 32px;
}

.element-image {
  width: 100%;
  max-width: 100%;
  margin: 20px auto;
}

.element-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.element-video {
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  border-radius: 12px;
}

.element-video img,
.element-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 12px;
}

.element-video-text h2,
.element-image-text h2 {
  width: 40%;
  text-align: right !important;
}

.element-video-text h3,
.element-image-text h3 {
  width: 40%;
  text-align: right !important;
  justify-content: center;
}

.element-video-text p,
.element-image-text p {
  width: 60%;
  text-align: left;
  font-size: 21px;
  line-height: 1.381002381;
  font-weight: 400;
  letter-spacing: 0.011em;
}

.continue-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  border-radius: 12px;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.continue-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
  transform: translateX(-100%) rotate(45deg);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.continue-button:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.continue-button:hover::before {
  transform: translateX(100%) rotate(45deg);
}

.continue-button:active {
  transform: translateY(1px) scale(0.98);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
}

.CTA_Group {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 1.8s;
}

.CTA_description {
  width: 60%;
  text-align: center;
  margin: 2rem auto;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.5;
  opacity: 0.9;
}

.prototype--background--C7T7U {
  position: unset;
  display: none;
  border: 1px solid red;
}

.embed_documentation_footer--documentationFooter--cRlI0 {
  display: none !important;
}

.element-mockup iframe {
  border: 1px solid red;
  height: 200px;
  overflow: hidden;
}

/* src/components/Element.css */
.element-grid {
  text-align: center;
  margin: 20px 0;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 10px;
  justify-content: center;
  max-width: 1200px; /* Ensure it does not exceed 3 columns */
}

.grid-item {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: auto;
  justify-content: center;
  vertical-align: middle;
}

.grid-item h3 {
  text-align: left !important;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  font-size: 32px;
  font-weight: 500;
}

.grid-item .element-text {
  margin: 0 !important;
  width: 100%;
  
}

.grid-item p {
  margin: 10px 0 0;
  font-size: 20px;
  text-align: left;
  font-weight: 200;
  font-family: 'SF Pro Text', sans-serif;
}

.grid-item .element-image .element-image-text {
  display: flex;
  flex-direction: column;
  margin: 0px;
  margin-top: 16px;
  text-align: center;
  width: 100%;
  gap: 16px;
  font-family: 'SF Pro Text', sans-serif;
}

.grid-item .element-image .element-image-text h3 {
  width: 100%;
  padding: 0px;
  margin: 0px !important;
  font-weight: 600px;
  font-size: 26px;
  text-align: left !important;
}

.grid-item .element-image .element-image-text p {
  width: 100%;
  padding: 0px;
  margin: 0px;
  text-align: left;
}

.portfolio-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e55608;
  opacity: 0.7;
  color: white;
  border: none;
  padding: 18px;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 40px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, opacity 0.3s;
}

.scroll-button:hover {
  background-color: #e55608;
  opacity: 1;
  box-shadow: 0 0 6px #f2e2b4;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

@keyframes fadeInAndUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.profile-pic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  opacity: 0;
  transform: scale(0.8);
  animation: fadeInAndScale 0.8s ease forwards;
}

.greetingHeadline {
  margin: 0px;
  margin-top: 16px;
  font-size: 80px;
  width: 920px;
  margin-bottom: 32px;
  transition: width 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 1.5s;
}

.timeline-item {
  transform-style: preserve-3d;
  transition: transform 0.3s ease;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
  animation-delay: 2.4s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInAndScale {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
    visibility: hidden;
  }
  1% {
    visibility: visible;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.case-study.fade-out {
  animation: fadeOut 0.3s ease forwards;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .case-study-overlay {
    padding-top: 0;
  }

  .logo-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 2rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    z-index: 10;
    margin-bottom: 0;
  }

  .logo-pic {
    width: 120px;
    height: 120px;
    margin-bottom: 2rem;
  }

  .logo-container h1 {
    font-size: clamp(42px, 8vw, 72px);
    margin: 1rem 0;
    line-height: 1.2;
    max-width: 90%;
  }

  .logo-container h2 {
    font-size: 1.2em;
    margin: 1rem 0 2rem;
    max-width: 90%;
    line-height: 1.5;
  }

  .CTA_Group {
    margin-top: 2rem;
  }

  .CTA_description {
    width: 90%;
    font-size: 1.1em;
    margin: 1.5rem auto;
  }

  .content-blocks {
    margin-top: 100vh;
    padding: 0 1rem;
  }

  .content-block {
    margin-bottom: 160px;
  }

  .case-study h1 {
    font-size: clamp(36px, 6vw, 64px);
    margin: 0.5rem 0;
  }

  .case-study h2 {
    font-size: 1.1em;
    margin: 0.5rem 0 1rem;
    max-width: 100%;
  }

  .element-video-text,
  .element-image-text {
    flex-direction: column;
    gap: 16px;
  }

  .element-video-text h2,
  .element-image-text h2,
  .element-video-text h3,
  .element-image-text h3 {
    width: 100%;
    text-align: left !important;
  }

  .element-video-text p,
  .element-image-text p {
    width: 100%;
    font-size: 18px;
  }

  .grid-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .grid-item {
    padding: 16px;
  }

  .grid-item h3 {
    font-size: 24px;
  }

  .grid-item p {
    font-size: 16px;
  }

  .continue-button {
    padding: 14px 28px;
    font-size: 15px;
  }
}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .case-study-overlay {
    padding-top: 60px;
  }

  .logo-container {
    padding: 1.5rem;
  }

  .logo-pic {
    width: 100px;
    height: 100px;
  }

  .logo-container h1 {
    font-size: clamp(36px, 7vw, 64px);
  }

  .logo-container h2 {
    font-size: 1.1em;
    max-width: 95%;
  }

  .content-blocks {
    padding: 0 0.5rem;
  }

  .content-block {
    margin-bottom: 120px;
  }

  .element-text h3 {
    font-size: 20px;
  }

  .element-text p,
  .element-image p {
    font-size: 16px;
  }

  .CTA_Group {
    margin-top: 20px;
  }

  .CTA_description {
    width: 95%;
    font-size: 1em;
  }

  .continue-button {
    padding: 12px 24px;
    font-size: 14px;
    width: 100%;
    max-width: 280px;
  }
}
