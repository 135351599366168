:root {
  --color-bg1: rgb(0, 0, 0);
  --color-bg2: rgb(0, 0, 0);
  --color1: 180, 213, 100;
  --color4: 243, 1, 182;
  --color3: 100, 220, 255;
  --color2: 200, 50, 50;
  --color5: 232, 122, 152;
  --color-interactive: 140, 100, 255;
  --circle-size: 100%;
  --blending: hard-light;
}

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}

.gradient-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}

.gradient-bg svg {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.gradients-container {
  filter: url(#goo) blur(60px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.g1 {
  position: absolute;
  background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 50%) no-repeat;
  mix-blend-mode: var(--blending);
  width: calc(var(--circle-size) * 1.5);
  height: var(--circle-size);
  top: calc(50% - var(--circle-size) / 2);
  left: calc(50% - var(--circle-size) / 2);
  transform-origin: center center;
  animation: moveVertical 80s ease infinite;
  opacity: .5;
}

.g2 {
  position: absolute;
  background: radial-gradient(circle at center, rgba(var(--color2), 0.8) 0, rgba(var(--color2), 0) 40%) no-repeat;
  mix-blend-mode: var(--blending);
  width: var(--circle-size);
  height:  calc(var(--circle-size) * 1.5);
  top: calc(50% - var(--circle-size) / 2);
  left: calc(50% - var(--circle-size) / 2);
  transform-origin: calc(50% - 400px);
  animation: moveInCircle 60s reverse infinite;
  opacity: .5;
}

.g3 {
  position: absolute;
  background: radial-gradient(circle at center, rgba(var(--color3), 0.8) 0, rgba(var(--color3), 0) 40%) no-repeat;
  mix-blend-mode: var(--blending);
  width: var(--circle-size);
  height: var(--circle-size);
  top: calc(50% - var(--circle-size) / 2 + 200px);
  left: calc(50% - var(--circle-size) / 2 - 500px);
  transform-origin: calc(50% + 400px);
  animation: moveInCircle 100s linear infinite;
  opacity: .5;
}

.g4 {
  position: absolute;
  background: radial-gradient(circle at center, rgba(var(--color4), 0.8) 0, rgba(var(--color4), 0) 40%) no-repeat;
  mix-blend-mode: var(--blending);
  width: var(--circle-size);
  height: var(--circle-size);
  top: calc(50% - var(--circle-size) / 2);
  left: calc(50% - var(--circle-size) / 2);
  transform-origin: calc(50% - 200px);
  animation: moveHorizontal 100s ease infinite;
  opacity: 0.5;
}

.g5 {
  position: absolute;
  background: radial-gradient(circle at center, rgba(var(--color5), 0.8) 0, rgba(var(--color5), 0) 40%) no-repeat;
  mix-blend-mode: var(--blending);
  width: calc(var(--circle-size) * 2);
  height: calc(var(--circle-size) * 2);
  top: calc(50% - var(--circle-size));
  left: calc(50% - var(--circle-size));
  transform-origin: calc(50% - 800px) calc(50% + 200px);
  animation: moveInCircle 60s ease infinite;
  opacity: .5;
}

.interactive {
  position: absolute;
  background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 20%) no-repeat;
  mix-blend-mode: var(--blending);
  width: 100%;
  height: 100%;
  top: -50%;
  left: -50%;
  opacity: 0.5;
}

.gradient-orbs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -2;
  background: #000;
}

.orb {
  position: absolute;
  border-radius: 50%;
  filter: blur(80px);
  opacity: 0.5;
  mix-blend-mode: screen;
  animation: float 20s ease-in-out infinite;
  background: radial-gradient(circle at center, rgba(229, 86, 8, 0.8), rgba(246, 160, 0, 0.3));
}

/* Add noise overlay */
.gradient-orbs::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  opacity: 0.15;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.6' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  mix-blend-mode: soft-light;
  animation: grainAnimation 0.8s steps(2) infinite;
}

/* Add a second layer of noise for more intensity */
.gradient-orbs::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  opacity: 0.08;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='1.2' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  mix-blend-mode: overlay;
  animation: grainAnimation 0.6s steps(2) infinite reverse;
}

@keyframes grainAnimation {
  0%, 100% { transform: translate(0, 0) scale(1); }
  10% { transform: translate(-2%, -2%) scale(1.02); }
  20% { transform: translate(2%, 2%) scale(0.98); }
  30% { transform: translate(-3%, 3%) scale(1.03); }
  40% { transform: translate(3%, -3%) scale(0.97); }
  50% { transform: translate(-2%, 3%) scale(1.02); }
  60% { transform: translate(3%, 2%) scale(0.98); }
  70% { transform: translate(-3%, -2%) scale(1.03); }
  80% { transform: translate(2%, -3%) scale(0.97); }
  90% { transform: translate(-2%, 2%) scale(1.02); }
}

@keyframes float {
  0% {
    transform: translate(0, 0) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

.orb1 {
  width: 400px;
  height: 400px;
  top: -100px;
  left: -100px;
  animation-delay: 0s;
}

.orb2 {
  width: 600px;
  height: 600px;
  top: 50%;
  right: -200px;
  animation-delay: -5s;
}

.orb3 {
  width: 300px;
  height: 300px;
  bottom: -100px;
  left: 30%;
  animation-delay: -10s;
}

/* Add vintage color tint */
.gradient-bg::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  background: linear-gradient(45deg, rgba(229, 86, 8, 0.07), rgba(246, 160, 0, 0.07));
  mix-blend-mode: color-burn;
}

/* Add divider line */
.greeting-divider {
  width: 360px;
  height: 1px;
  background: linear-gradient(90deg, rgba(229, 86, 8, 0.3), rgba(246, 160, 0, 0.3));
  margin: 20px 0;
  opacity: 0.5;
  border-radius: 1px;
}

.story-button {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  margin: 40px 0;
}

.story-button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: linear-gradient(90deg, rgba(229, 86, 8, 0.5), rgba(246, 160, 0, 0.5));
  transition: width 0.3s ease;
}

.story-button:hover {
  color: rgba(255, 255, 255, 0.9);
}

.story-button:hover::after {
  width: 100%;
}

/* Add scroll indicator */
.scroll-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  opacity: 0.9;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.scroll-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;
  font-family: "SF Mono", SFMono-Regular, ui-monospace, monospace;
  letter-spacing: 0.05em;
  margin-bottom: 12px;
}

.scroll-arrow {
  width: 24px;
  height: 24px;
  border-right: 2px solid rgba(229, 86, 8, 0.7);
  border-bottom: 2px solid rgba(246, 160, 0, 0.7);
  transform: rotate(45deg);
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) rotate(45deg);
  }
  40% {
    transform: translateY(-10px) rotate(45deg);
  }
  60% {
    transform: translateY(-5px) rotate(45deg);
  }
}

.scroll-indicator:hover {
  opacity: 1;
}

h1 {
  font-size: 3.5rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 1s;
}

h2 {
  font-size: 1.5rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 2.5s;
} 