.portfolio-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-out;
}

.portfolio-overlay-content {
  width: 90%;
  max-width: 1600px;
  height: 90vh;
  padding: 2rem;
  overflow-y: auto;
  color: white;
}

.portfolio-overlay-content h2 {
  font-size: 3rem;
  margin-bottom: 2rem;
  font-weight: 500;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.close-overlay {
  position: fixed;
  top: 2rem;
  right: 2rem;
  background: none;
  border: none;
  color: white;
  font-size: 3rem;
  cursor: pointer;
  z-index: 1001;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.close-overlay:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Override CaseStudyThumbnail styles for the overlay */
.portfolio-overlay .case-study-thumbnail {
  height: 500px;
  width: 100%;
  transition: transform 0.3s;
}

.portfolio-overlay .case-study-thumbnail:hover {
  width: 100%;
  transform: scale(1.02);
}

.portfolio-overlay .case-study-details h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.portfolio-overlay .case-study-details p {
  font-size: 1.2rem;
  opacity: 1;
  width: 100%;
} 