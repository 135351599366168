/* Chat Controls */
.chat-controls-top {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.chat-controls-group {
  display: flex;
  align-items: center;
  background: rgba(60, 60, 60, 0.95);
  padding: 3px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.mode-option {
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 14px;
  border: none;
  border-radius: 16px;
  background: transparent;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 13px;
  font-weight: 500;
  min-height: 28px;
  opacity: 1;
  transform: translateX(0);
  width: auto;
}

.mode-option:hover:not(.active):not(:disabled) {
  color: rgba(255, 255, 255, 0.9);
  background: rgba(255, 255, 255, 0.1);
}

.mode-option.active {
  background: white;
  color: #000;
  font-weight: 600;
}

.mode-option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.go-option {
  background: #10B981;
  color: white;
  margin-left: 2px;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  transform: translateX(0);
  width: auto;
  overflow: hidden;
}

.go-option:hover {
  background: #059669;
}

.go-option.hidden {
  opacity: 0;
  transform: translateX(-10px);
  width: 0;
  padding: 0;
  margin: 0;
}

.chat-tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 12px;
  font-size: 13px;
  white-space: nowrap;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  animation: tooltipBounce 0.6s ease;
  display: flex;
  align-items: center;
  gap: 6px;
  top: calc(100% + 8px);
  z-index: 9999;
}

.chat-tooltip::before {
  content: '';
  position: absolute;
  top: -6px;
  right: 120px;
  width: 12px;
  height: 12px;
  background: rgba(0, 0, 0, 0.8);
  transform: rotate(45deg);
  border-radius: 2px;
}

.pointing-hand {
  font-size: 14px;
  animation: handWave 2s infinite;
  margin-top: -1px;
}

@keyframes handWave {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

@keyframes tooltipBounce {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Remove all old styles */
.chat-title,
.mode-icon,
.chat-button,
.go-button,
.chat-mode-selector,
.chat-header-group,
.live-toggle-container,
.live-switch,
.live-slider,
.live-indicator,
.chat-controls,
.live-toggle,
.toggle-slider,
.toggle-label,
.live-status,
.chat-controls-old {
  display: none !important;
}

.chatbot-container {
  position: fixed;
  bottom: 32px;
  right: 16px;
  z-index: 9999;
  transition: all 0.3s ease;
}

.chatbot-container.expanded {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.chatbot-container.expanded > * {
  pointer-events: auto;
}

.chat-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: transform 0.2s ease;
}

.go-button {
  background: linear-gradient(135deg, #007AFF 0%, #0056b3 100%);
  color: white;
  padding: 8px 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 122, 255, 0.3);
}

.chat-button:hover .go-button {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.4);
  background: linear-gradient(135deg, #0084ff 0%, #0062cc 100%);
}

/* Remove old profile picture styles */
.chat-button img {
  display: none;
}

.chat-button img.recording {
  animation: recordingPulse 1.5s infinite;
  border-color: #7B68EE;
  box-shadow: 0 0 0 rgba(123, 104, 238, 0.4);
}

@keyframes recordingPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(123, 104, 238, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(123, 104, 238, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(123, 104, 238, 0);
  }
}

.chat-panel {
  position: fixed;
  top: 16px;
  bottom: 32px;
  right: 16px;
  width: 420px;
  height: calc(100vh - 48px);
  background: linear-gradient(160deg, #1E1E1E, #252530);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  z-index: 9999;
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 16px 70px;
  background: rgba(30, 30, 30, 0.9);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
  justify-content: center;
  min-height: 100px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;
}

.chat-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  pointer-events: none;
  width: auto;
  max-width: 60%;
  left:14px;
}

.chat-header-profile {
  position: relative;
  width: 60px;
  height: 60px;
  margin-bottom: 8px;
  pointer-events: auto;
}

.chat-header-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.active-status {
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 12px;
  height: 12px;
  background-color: #22c55e;
  border-radius: 50%;
  border: 2px solid rgba(30, 30, 30, 0.9);
  z-index: 2;
}

.chat-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.close-button {
  position: absolute;

  right:-146px;
  top: 17%;
  transform: translateY(-50%);
  background: rgba(60, 60, 60, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.9);
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
  position: relative;
  z-index: 20;
  pointer-events: auto;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.close-button:hover {
  background: rgba(80, 80, 80, 0.9);
  color: white;
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.close-button:hover::after {
  content: "Collapse chat";
  position: absolute;
  top: -30px;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
}

.collapse-icon {
  font-size: 28px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: -2px;
}

.timestamp-divider {
  text-align: center;
  margin: 16px 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-weight: 500;
  position: relative;
}

.avatar-selection {
  padding: 20px;
  text-align: center;
  background: #1E1E1E;
  width: 100%;
}

.avatar-selection h4 {
  margin: 0 0 16px;
  color: white;
  font-size: 1.2em;
}

.avatar-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  padding: 0 16px;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.avatar-button {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-button:hover {
  transform: scale(1.1);
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(45, 45, 45, 0.2);
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: transparent;
  margin-bottom: auto;
}

.message {
  display: flex;
  max-width: 85%;
  align-items: flex-start;
  animation: fadeIn 0.3s ease;
  margin-bottom: 8px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.message.assistant {
  align-self: flex-start;
}

.message.user {
  align-self: flex-end;
}

.message-content {
  padding: 12px 16px;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.02em;
  line-height: 1.4;
  position: relative;
  transition: all 0.2s ease;
}

.message.assistant .message-content {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-top-left-radius: 4px;
}

.message.user .message-content {
  background: #007AFF;
  color: white;
  border-top-right-radius: 4px;
}

.chat-input {
  padding: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  gap: 8px;
  background: #1E1E1E;
}

.chat-input textarea {
  flex: 1;
  background: #2D2D2D;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  resize: none;
  min-height: 40px;
  max-height: 120px;
  color: white;
}

.chat-input textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.send-button {
  background: #2D2D2D;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.send-button:hover {
  background: #3D3D3D;
}

.questions-dropdown {
  position: absolute;
  bottom: 100%;
  left: 16px;
  right: 16px;
  background: #2D2D2D;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.3);
  max-height: 200px;
  overflow-y: auto;
}

.question-option {
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  color: white;
}

.question-option:hover {
  background: rgba(45, 45, 45, 0.2);
}

/* Add custom scrollbar for the chat messages */
.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #1E1E1E;
}

.chat-messages::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Add a new class to handle content overflow */
.chat-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: linear-gradient(160deg, #1E1E1E, #252530);
  overflow-y: auto;
}

.mode-selection {
  padding: 32px 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80%;
}

.mode-selection h2 {
  font-size: 32px;
  margin-bottom: 8px;
  color: white;
  font-weight: 300;
  letter-spacing: -0.01em;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid transparent;
  width: 0;
  animation: typing 1s steps(20, end) forwards 1.5s, blink-caret 0.75s step-end 3;
}

@keyframes typing {
  from { 
    width: 0;
    opacity: 1;
  }
  to { 
    width: 100%;
    opacity: 1;
  }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgba(255, 255, 255, 0.6); }
}

.mode-subtitle {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 32px;
  font-weight: 300;
  max-width: 280px;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards 2.8s;
}

.mode-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 400px;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
}

.mode-button {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 24px;
  border: none;
  border-radius: 16px;
  background: linear-gradient(135deg, #3B82F6 0%, #1D4ED8 100%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: left;
}

.mode-button:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 1);
}

.mode-icon {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 14px;
  background: rgb(255, 255, 255, .2);
  flex-shrink: 0;
}

.mode-icon span {
  font-size: 32px;
  display: block;
  line-height: 1;
  transform: translateY(2px);
}

.mode-button:hover .mode-icon {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.05);
}

.mode-button:hover .mode-icon span {
  transform: translateY(2px) scale(1.1);
}

.mode-text {
  flex-grow: 1;
}

.mode-text h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  transition: all 0.3s ease;
}

.welcome-emoji {
  font-size: 36px;
  margin-bottom: 16px;
  opacity: 0;
  animation: wave-animation 2.5s ease forwards;
}

@keyframes wave-animation {
  0% {
    opacity: 0;
    transform: translateY(10px) rotate(0deg);
  }
  10% {
    opacity: 1;
    transform: translateY(0) rotate(0deg);
  }
  20% {
    transform: rotate(14deg);
  }
  30% {
    transform: rotate(-8deg);
  }
  40% {
    transform: rotate(14deg);
  }
  50% {
    transform: rotate(-4deg);
  }
  60% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotate(0deg);
  }
}

/* Mode button specific styles for a more welcoming feel */
.mode-button.work {
  background: linear-gradient(135deg, #470f7b 0%, #2147b0 100%);
}

.mode-button.work:hover {
  background: linear-gradient(135deg, #4e0791 0%, #2D5EE8 100%);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.mode-button.advice {
  background: linear-gradient(to right, rgba(245, 158, 11, 0.1), rgba(245, 158, 11, 0.05));
  border-color: rgba(245, 158, 11, 0.2);
}

.mode-button.advice .mode-icon {
  background: rgba(245, 158, 11, 0.2);
}

.mode-button.mentoring {
  background: linear-gradient(135deg, #8e078e 0%, #059669 100%);
}

.mode-button.mentoring:hover {
  background: linear-gradient(135deg, #8e20c9 0%, #15A679 100%);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(16, 185, 129, 0.3);
}

/* Add styles for the main content shift */
body {
  transition: padding-right 0.3s ease;
  padding-right: 0;
}

body.chat-expanded {
  padding-right: 452px; /* 420px (panel width) + 32px (panel margins) */
}

.assistant-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.typing-indicator {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  border-top-left-radius: 4px;
  width: fit-content;
  margin: 8px 0;
}

.typing-indicator-dot {
  width: 6px;
  height: 6px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  animation: typingAnimation 1.4s infinite;
  animation-fill-mode: both;
}

@keyframes typingAnimation {
  0% {
    transform: translateY(0px);
    opacity: 0.5;
  }
  25% {
    transform: translateY(-3px);
    opacity: 1;
  }
  50% {
    transform: translateY(0px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0px);
    opacity: 0.5;
  }
}

.back-button {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(45, 45, 45, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #007AFF;
  font-size: 16px;
  padding: 6px 10px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
  transition: all 0.2s ease;
  z-index: 20;
}

.back-button:hover {
  background: rgba(60, 60, 60, 0.8);
  border-color: rgba(255, 255, 255, 0.2);
}

.back-arrow {
  font-size: 28px;
  line-height: 1;
  margin-right: 1px;
}

.mode-button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Hide chat bubble on mobile */
@media (max-width: 768px) {
  .chat-bubble {
    display: none;
  }
}

.voice-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  gap: 12px;
}

.voice-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
}

.voice-status-text {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  line-height: 1.4;
}

.voice-action-hint {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
}

.voice-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  background: linear-gradient(135deg, #7B68EE 0%, #483D8B 100%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.voice-button::after {
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(123, 104, 238, 0.2) 0%, rgba(72, 61, 139, 0.2) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.voice-button:hover::after {
  opacity: 1;
}

.voice-button.recording {
  background: linear-gradient(135deg, #6B68EE 0%, #483D8B 100%);
  animation: pulse 1.5s infinite;
}

.voice-button.playing {
  background: linear-gradient(135deg, #6B68EE 0%, #483D8B 100%);
}

.voice-button .mode-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.voice-button:hover .mode-icon {
  transform: scale(1.1);
}

.voice-button.recording .mode-icon {
  animation: recordingPulse 1.5s infinite;
}

@keyframes recordingPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.voice-button.playing .mode-icon {
  animation: playingWave 1.5s infinite;
}

@keyframes playingWave {
  0% {
    transform: scale(1) translateY(0);
  }
  25% {
    transform: scale(1.1) translateY(-2px);
  }
  50% {
    transform: scale(1) translateY(0);
  }
  75% {
    transform: scale(1.1) translateY(2px);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}

.chat-controls {
  display: flex;
  align-items: center;
  gap: 12px;
}

.live-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
}

.live-toggle input {
  display: none;
}

.toggle-slider {
  position: relative;
  width: 44px;
  height: 24px;
  background: rgba(45, 45, 45, 0.5);
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.toggle-slider::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
  top: 2px;
  left: 2px;
  transition: all 0.3s ease;
}

.live-toggle input:checked + .toggle-slider {
  background: linear-gradient(135deg, #7B68EE 0%, #483D8B 100%);
  border-color: rgba(255, 255, 255, 0.2);
}

.live-toggle input:checked + .toggle-slider::before {
  transform: translateX(20px);
}

.toggle-label {
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.live-toggle:hover .toggle-label {
  opacity: 1;
}

.live-status {
  position: absolute;
  bottom: -24px;
  right: 0;
  font-size: 12px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 4px 8px;
  border-radius: 4px;
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
  opacity: 0.8;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.live-status:hover {
  opacity: 1;
}

.chatbot-container.live-mode .chat-button img {
  border-color: #7B68EE;
}

.live-toggle-container {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
}

.live-switch {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.live-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.live-slider {
  display: flex;
  align-items: center;
  padding: 4px 12px 4px 32px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  font-size: 14px;
  color: #666;
  transition: all 0.3s ease;
}

.live-slider .live-indicator {
  position: absolute;
  left: 4px;
  width: 20px;
  height: 20px;
  background: #ccc;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.live-switch input:checked + .live-slider {
  background: rgba(82, 196, 26, 0.2);
  color: #52c41a;
}

.live-switch input:checked + .live-slider .live-indicator {
  background: #52c41a;
  box-shadow: 0 0 8px rgba(82, 196, 26, 0.5);
}

.live-switch input:disabled + .live-slider {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Pulse animation for active state */
@keyframes livePulse {
  0% {
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(82, 196, 26, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
  }
}

.live-switch input:checked + .live-slider .live-indicator {
  animation: livePulse 2s infinite;
}

/* Adjust chat header group to accommodate the toggle */
.chat-header-group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 100px; /* Make room for the toggle */
}

.chat-mode-selector {
  display: flex;
  gap: 8px;
  background: rgba(255, 255, 255, 0.1);
  padding: 4px;
  border-radius: 20px;
} 