.password-protection {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(10px);
}

.password-container {
  background-color: #1E1E1E;
  padding: 40px;
  border-radius: 12px;
  max-width: 400px;
  width: 90%;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.password-container h2 {
  color: white;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 500;
}

.password-container p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 24px;
  font-size: 16px;
}

.input-group {
  margin-bottom: 20px;
  position: relative;
}

.password-container input {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 16px;
  transition: all 0.3s ease;
}

.password-container input:focus {
  outline: none;
  border-color: #8B5CF6;
  background-color: rgba(255, 255, 255, 0.1);
}

.password-container input.error {
  border-color: #EF4444;
}

.error-message {
  color: #EF4444;
  font-size: 14px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  text-align: center;
}

.password-container button {
  background-color: #8B5CF6;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.password-container button:hover {
  background-color: #7C3AED;
}

.password-container button:active {
  transform: scale(0.98);
} 