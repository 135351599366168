.portfolio-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem;
}

.timeline-section {
  max-width: 1200px;
  margin: 60px 0;
  padding: 0 20px 0 100px;
}

.timeline-section h2 {
  text-align: left;
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  font-weight: 600;
  margin-left: 100px;
}

.timeline {
  position: relative;
  width: 100%;
  max-width: 800px;
  padding: 40px 20px;
  opacity: 1;
  transform: translateY(30px);
margin-top:50px;
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 2.2s;
  perspective: 1000px;
  transform-style: preserve-3d;
  will-change: transform, opacity;

}
.timeline-static {
  position: relative;
  width: 100%;
  max-width: 800px;
  padding: 40px 20px;
  opacity: 1;
  
margin-top:50px;
 
  perspective: 1000px;

  will-change: transform, opacity;

}
.timeline-line {
  position: absolute;
  width: 1px;
  height: 100%;
  background: repeating-linear-gradient(
    to bottom,
    #e55608 0%,
    #e55608 6px,
    transparent 6px,
    transparent 12px
  );
  left: 90px;
  top: 100px;
  z-index: 1;
  opacity: 0.4;
}

.timeline-item {
  width: calc(100% - 150px);
  min-height: 100px;
  position: relative;
  left: 50px;
  opacity: 0;
  transform: translateY(20px);
  margin-bottom: 20px;
  transform-style: preserve-3d;
  transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1);
  animation: fadeIn 0.3s ease forwards;
  animation-delay: 2.4s;
  z-index: 2;
}


.timeline-logo {
  position: relative;
  margin-left: 24px;
  top: 50px;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  padding: 6px;
  background: #121212;
  box-shadow: 0 2px 8px rgba(75, 75, 75, 0.4);
  z-index: 2;
}

.timeline-logo:hover {
  transform: translate(-50%, -50%) scale(1.15);
  box-shadow: 0 0 30px rgba(75, 75, 75, 0.4);
}

.timeline-logo img {
  width: 123%;
  height: 125%;
  object-fit: cover;
  position: relative;
  top: -6px;
  left: -7px;
}

.timeline-logo:hover img {
  transform: scale(1.1);
}

.timeline-content {
  position: relative;
  text-align: left;
  padding: 32px;
  border-radius: 12px;
  transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1);
  cursor: pointer;
  overflow: visible;
  left: 100px;
  top: -100px;
}

.timeline-content h3 {
  color: rgba(255, 255, 255, 0.9);
  font-size: 2.0rem;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.3;
  margin: 0 0 10px;
  transition: all 0.3s ease;
}

.timeline-content p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  line-height: 1.6;
  font-style: normal;
  font-weight: 200;
  font-size: 1.4rem;
}

.timeline-year {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  font-weight: 200;
  margin-bottom: 16px;
  letter-spacing: 0.05em;
  transition: all 0.3s ease;
  font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

.timeline-detail {
  position: relative;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.8s cubic-bezier(0.4, 0.0, 0.2, 1),
              opacity 0.5s cubic-bezier(0.4, 0.0, 0.2, 1),
              margin 0.5s cubic-bezier(0.4, 0.0, 0.2, 1);
  margin-top: 0;
  pointer-events: none;
  transform-origin: top;
  transform: scaleY(0);
}

.timeline-detail-image {
  width: 100%;
  border-radius: 12px;
  margin-bottom: 24px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s cubic-bezier(0.4, 0.0, 0.2, 1);
  transition-delay: 0.2s;
}

.timeline-detail-video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  margin-bottom: 24px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s cubic-bezier(0.4, 0.0, 0.2, 1);
  transition-delay: 0.2s;
}

.timeline-detail-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.timeline-detail-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  line-height: 1.7;
  font-weight: 400;
  margin-bottom: 24px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s cubic-bezier(0.4, 0.0, 0.2, 1);
  transition-delay: 0.3s;
}

/* Expanded states */
.timeline-item.expanded {
  scroll-margin-top: 100px;
  transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.timeline-item.expanded .timeline-content {
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(0);
  transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.timeline-item.expanded .timeline-detail {
  max-height: 2000px;
  opacity: 1;
  margin-top: 20px;
  pointer-events: auto;
  transform: scaleY(1);
}

.timeline-item.expanded .timeline-detail-image,
.timeline-item.expanded .timeline-detail-video,
.timeline-item.expanded .timeline-detail-description {
  opacity: 1;
  transform: translateY(0);
}

/* Hover states */
.timeline-item:hover .timeline-content h3 {
  color: rgba(255, 255, 255, 1);
}

.timeline-item:hover .timeline-content p {
  color: rgba(255, 255, 255, 0.8);
}

.timeline-item:hover .timeline-year {
  color: rgba(255, 255, 255, 0.6);
}

.timeline-item:hover .timeline-content {
  background: rgba(40, 40, 40, 0.3);
  backdrop-filter: blur(10px);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Timeline */
@media (max-width: 768px) {
  .timeline-line {
    left: 20px;
  }
  
  .timeline-item {
    width: calc(100% - 40px);
    left: 20px;
    padding-left: 40px;
  }
  
  .timeline-content {
    width: 100%;
    margin-left: 0;
    padding: 20px;
  }
}

.timeline-content[style*="cursor: pointer"] {
  position: relative;
  overflow: hidden;
}

.timeline-content[style*="cursor: pointer"]::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(75, 75, 75, 0.1);
  opacity: 0;
  border-radius: 15px;
  pointer-events: none;
}

.timeline-content[style*="cursor: pointer"]:hover h3 {
  color: #ffffff;
}

.timeline-content[style*="cursor: pointer"]:active {
  transform: translateY(0px);
  box-shadow: 0 4px 30px rgba(75, 75, 75, 0.1);
}

.timeline-view-button {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(
    45deg,
    rgba(40, 40, 40, 0.9),
    rgba(70, 70, 70, 0.9),
    rgba(40, 40, 40, 0.9)
  );
  background-size: 200% 200%;
  color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  animation: gradientShift 3s ease infinite;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.timeline-view-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transition: 0.5s;
}

.timeline-view-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.4);
  background-position: right center;
  color: rgba(255, 255, 255, 1);
  border-color: rgba(255, 255, 255, 0.2);
}

.timeline-view-button:hover::before {
  left: 100%;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Add new keyframes for smooth expansion */
@keyframes expandContent {
  0% {
    max-height: 0;
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    max-height: 2000px;
    opacity: 1;
    transform: scaleY(1);
  }
} 