.portfolio-nav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: transparent;
  backdrop-filter: none;
  border-right: none;
  padding: 20px;
  color: white;
  z-index: 1000;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

/* Light mode styles */
.portfolio-nav.light-mode {
  color: #000;
}

.portfolio-nav.light-mode .nav-item {
  background-color: rgba(0, 0, 0, 0.05);
}

.portfolio-nav.light-mode .nav-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.portfolio-nav.light-mode .nav-item.active {
  background-color: rgba(0, 0, 0, 0.15);
}

.portfolio-nav.light-mode .nav-item svg.nav-item-logo {
  color: rgba(0, 0, 0, 0.7);
}

.portfolio-nav.light-mode .nav-profile-icon {
  background: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.3);
}

.portfolio-nav.light-mode .nav-profile-icon:hover {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.6);
}

.portfolio-nav.light-mode .nav-profile-icon svg,
.portfolio-nav.light-mode .social-icon {
  color: rgba(0, 0, 0, 0.8);
}

.portfolio-nav.expanded {
  width: 280px;
  background-color: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.portfolio-nav.light-mode.expanded {
  background-color: rgba(255, 255, 255, 0.95);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.portfolio-nav.collapsed {
  width: 66px;
  height: auto;
  padding: 10px;
  background-color: transparent;
  backdrop-filter: none;
  border-right: none;
}

.nav-header {
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collapsed .nav-header {
  padding: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.nav-profile-icon {
  min-width: 56px;
  width: 56px;
  min-height: 56px;
  height: 56px;
  border-radius: 50%;
  background: rgba(139, 92, 246, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid rgba(139, 92, 246, 0.3);
  box-shadow: 0 0 10px rgba(139, 92, 246, 0.2);
  flex-shrink: 0;
  margin: 0 auto;
}

.nav-profile-icon:hover {
  transform: scale(1.1);
  border-color: rgba(139, 92, 246, 0.6);
  box-shadow: 0 0 20px rgba(139, 92, 246, 0.4);
  background: rgba(139, 92, 246, 0.2);
}

.nav-profile-icon svg {
  width: 30px;
  height: 30px;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.3s ease;
}

.nav-profile-icon:hover svg {
  color: white;
}

.nav-header h3 {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.collapsed .nav-header h3 {
  opacity: 0;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}

.nav-items {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  overflow-y: auto;
  padding-top: 8px;
}

/* Keep slide animation but remove blinking */
.nav-items > *:not(:first-child) {
  visibility: hidden;
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.3s ease;
}

/* Show items when expanded */
.portfolio-nav.expanded .nav-items > *:not(:first-child) {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

/* Hide items when collapsed */
.portfolio-nav.collapsed .nav-items > *:not(:first-child) {
  display: none;
  visibility: hidden;
  opacity: 0;
}

/* Remove all animation delays */
.portfolio-nav.expanded .nav-items > *:nth-child(2),
.portfolio-nav.expanded .nav-items > *:nth-child(3),
.portfolio-nav.expanded .nav-items > *:nth-child(4),
.portfolio-nav.expanded .nav-items > *:nth-child(5),
.portfolio-nav.expanded .nav-items > *:nth-child(6),
.portfolio-nav.expanded .nav-items > *:nth-child(7),
.portfolio-nav.expanded .nav-items > *:nth-child(8),
.portfolio-nav.expanded .nav-items > *:nth-child(9) {
  animation: none;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.05);
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateX(5px);
}

.nav-item.active {
  background-color: rgba(255, 255, 255, 0.15);
  border-left: 3px solid #2D2D2D;
}

/* Remove all wave/blinking effects */
.nav-item::after,
.nav-item:hover::after,
.nav-item.active::after {
  display: none;
}

.nav-item-logo {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 12px;
  object-fit: cover;
  flex-shrink: 0;
}

/* Style for the home icon */
.nav-item svg.nav-item-logo {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left:6px;
  color: rgba(255, 255, 255, 0.7);
  width: 32px;
  height: 32px;
}

.nav-item-content {
  flex: 1;
  opacity: 1;
  transition: opacity 0.3s ease;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.collapsed .nav-item-content {
  opacity: 0;
}

.nav-item-content h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  flex-shrink: 0;
}

.nav-item-content p {
  margin: 4px 0 0;
  font-size: 14px;
  opacity: 0.7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Remove padding adjustments for main content */
.home-container {
  transition: none;
  padding-left: 0;
}

.home-container.nav-collapsed {
  padding-left: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .portfolio-nav {
    width: 60px;
    height: auto;
    padding: 10px;
  }
  
  .portfolio-nav.expanded {
    width: 240px;
    height: 100vh;
  }

  .home-container {
    padding-left: 0;
  }
}

.nav-footer {
  display: none;
}

.social-icons {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-left: auto;
}

.collapsed .social-icons {
  display: none;
}

.social-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s ease;
}

.social-icon:hover {
  color: rgba(255, 255, 255, 1);
  transform: translateY(-2px);
}

.light-mode .social-icon {
  color: rgba(0, 0, 0, 0.7);
}

.light-mode .social-icon:hover {
  color: rgba(0, 0, 0, 1);
}

/* Add smooth transition for project content */
.project-content {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.project-content.transitioning {
  opacity: 0;
  transform: translateY(20px);
} 