.chat-header-group {
  position: absolute;
  bottom: 70px;
  right: 0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.profile-bubble {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgba(109, 28, 179, 0.3);
  box-shadow: 0 2px 8px rgba(109, 28, 179, 0.2);
}

.chat-bubble {
  position: relative;
  background: linear-gradient(135deg, #454545, #000000);
  padding: 12px 20px;
  border-radius: 20px;
  color: white;
  font-size: 15px;
  font-weight: 300;
  white-space: nowrap;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 0.5s ease forwards, pulse 2s infinite ease-in-out;
  z-index: 1000;
}

.chat-bubble::after {
  content: '';
  position: absolute;
  bottom: -8px;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000000;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
} 