body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Segoe UI', system-ui, Roboto, sans-serif;
  transition: "all 0.5s";
}

body {
  color: var(--text-color, #fff); /* Use CSS variable with default color */
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
/* Global link color */
a {
  color: #f6a000; /* Sets the default color for all links */
  text-decoration: none; /* Removes the underline from links, optional */
}

/* Link color on hover */
a:hover {
  color: rgb(255, 237, 212); /* Changes color when hovering over the link */
}

/* Link color when active (during click) */
a:active {
  color: rgb(255, 250, 250); /* Changes color when the link is being clicked */
}

/* Link color when visited */
a:visited {
  color: rgb(72, 44, 232); /* Changes color of visited links */
}

/* Custom scrollbar for webkit browsers */
::-webkit-scrollbar {
  width: 0px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  visibility: hidden;
}

::-webkit-scrollbar-thumb {
  visibility: hidden;
}

::-webkit-scrollbar-thumb:hover {
  background: red; /* Color when hovering over the scrollbar thumb */
}

.message {
  color: inherit; /* Ensure messages inherit the body's color */
}

.assistant-message {
  color: inherit; /* Ensure assistant messages inherit the body's color */
}

.home-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-blend-mode: overlay;
  overflow: visible;
  width: 100%;
  min-height: 100vh;
  margin-top: 40px;
  padding: 20px;
  overflow-x: hidden;
  opacity: 1;
}

.home-container.page-transition {
  opacity: 0;
}

/* Ensure all main content elements have correct width */
.profile-container,
.greetingMiniline,
.greetingHeadline,
.greetingMain {
  width: 100%;
}

/* Update widths when chat is expanded */
body.chat-expanded .profile-container,
body.chat-expanded .greetingMiniline,
body.chat-expanded .greetingHeadline,
body.chat-expanded .greetingMain {
  width: calc(100% - 360px);
}

h1 {
  font-size: 55px;
  line-height: 1.05;
  font-weight: 500;
  letter-spacing: -.015em;
}

h2 {
  font-size: 64px;
  line-height: 1.0714285714;
  font-weight: 600;
  letter-spacing: -0.005em;
}

h3 {
  font-size: 28px;
  line-height: 1.1428571429;
  font-weight: 500;
  letter-spacing: 0.007em;
}

p {
  font-size: 19px;
  line-height: 1.2105263158;
  font-weight: 600;
  letter-spacing: 0.012em;
}

.input-group {
  position: relative;
  width: 90%;

  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
}

.input-group.sticky {
  position: fixed;
  bottom: 0;
  width: 90%;

  border-radius: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.input-field-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 30px;
  padding: 6px 12px;
  width: 80%;
  max-width: 650px;
  background-color: #242424;
}

.input-field-group:hover {
  background-color: #242424;
}

.input-field {
  flex-grow: 1;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background-color: transparent; /* Remove background */
  font-size: 20px;
  padding-right: 40px; /* Space for the button */
  border: 0px;
  color: #d5d5d5;
}

.input-field:focus {
  outline: 0px;
  color: white;
}

.send-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 40px;
  background-color: #e55608;
  opacity: 0.7;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 36px;
  margin-left: 20px;
  padding-bottom: 8px;
  transition: background-color 0.3s, box-shadow 0.3s, opacity 0.3s;
}

.send-button:hover {
  background-color: #e55608;
  opacity: 1;
  box-shadow: 0 0 6px #f2e2b4;
}

.send-button::after {
  font-size: 36px;
  width: 45px;
  height: 40px;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.3;
}

.profile-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  transition: width 0.3s ease;
  animation: fadeIn 0.8s ease forwards;
  margin-top:40px
}

.profile-container-static {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  transition: width 0.3s ease;
  animation: fadeIn 0.8s ease forwards;
  margin-top:40px
}

body.chat-expanded .profile-container {
  width: calc(100% - 360px);
}

.profile-pic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}
.dropdown-menu {
  position: absolute;
  top: -350px;
  max-width: 650px;
  width: 80%;
  background-color: rgba(30, 30, 30, 1);
  border: 1px solid #838383;
  border-radius: 20px;
  overflow-y: auto;
  z-index: 100000;
}

.dropdown-item {
  padding: 20px;
  color: #d4d4d4;
  cursor: pointer;
  height: 30px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 20px;
  background-color: transparent;
  font-weight: 400;
  padding-left: 26px;
}

.dropdown-item:hover {
  background-color: #555;
}

.chat-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
  position: relative; /* Ensure that the parent container has a position value other than static */
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
}


/* src/components/Portfolio.css */
.portfolio {
  padding: 20px;
  width: auto;
  flex-direction: row;
  position: relative;
  left: 0;
  top: 0;

  white-space: nowrap; /* Prevent the content from wrapping to the next line */
  height: 400px;
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
  overflow-x: auto; /* Add scrolling */
}

.case-study-thumbnail-container {
  display: inline-block;
  margin: 0 10px; /* Adjust spacing as needed */
}

/* src/components/Portfolio.css */

.gallery-row {
  gap: 16px;
  display: flex;
  flex-direction: row;

  overflow-x: scroll;
  position: absolute;
  left: 0;
  width: max-content; /* Allow the width to be defined by its content */
}

.message {
  padding: 10px;
  border-radius: 5px;
  max-width: 80%;
}

.user-message {
  align-self: center;
  font-family: Inter;
  font-size: 26px;
  text-align: left;
  opacity: 0.9;
  margin: 16px;
  width: 60%;
  font-weight: 100;
  background-color: #3b3b3b;
  border-radius: 16px;
  display: flex;
  align-items: flex-start;
  padding: 16px;
}

.assistant-message {
  align-self: center;
  font-family: Inter;
  font-size: 26px;
  text-align: left;
  opacity: 0.9;
  width: 60%;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 16px;
}

.assistant-message-content {
  background-color: #3b3b3b;
  border-radius: 16px;
  padding: 16px;
  color: inherit;
  flex: 1;
}

.assistant-message-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
}

.greetingMain {
  font-weight: 400;
  margin: 0;
  margin-top: 8px;
  transition: width 0.3s ease;
}

body.chat-expanded .greetingMain {
  width: calc(100% - 360px);
}

.greetingHeadline {
  margin: 0px;
  margin-top: 16px;
  font-size: 80px;
  width: 100%;
  max-width: 920px;
  margin-bottom: 32px;
  transition: width 0.3s ease;
  text-align: center;
}

.greetingHeadline-static {
  margin: 0px;
  margin-top: 16px;
  font-size: 80px;
  width: 100%;
  max-width: 920px;
  margin-bottom: 32px;
  opacity: 1;
}

body.chat-expanded .greetingHeadline {
  width: calc(100% - 360px);
}

.greetingSubHeadline {
  margin: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-family: monospace;
}

.avatar-selection {
  width: 920px;
}

.hrLine {
  width: 620px;
  margin-top: 32px;
  margin-bottom: 32px;
  opacity: 0.1;
}

/* Portfolio Section */
.portfolio-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.greetingMiniline {
  font-size: 24px;
  font-weight: 200;
  margin-top: 16px;
  margin-bottom: 0px;
  transition: width 0.3s ease;
  text-align: center;
  width: 100%;
}

.greetingMiniline-static {
  font-size: 24px;
  font-weight: 200;
  margin-top: 16px;
  margin-bottom: 0px;
  opacity: 1;
}

body.chat-expanded .greetingMain {
  width: calc(100% - 360px);
}

.timeline-year {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  font-weight: 200;
  margin-bottom: 12px;
  font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica', 'Arial',
  sans-serif;
}


.content-block-inner {
 margin-top:30px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .home-container {
    margin-top: 20px;
    padding: 16px;
  }

  .profile-container,
  .profile-container-static {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .profile-pic {
    width: 100px;
    height: 100px;
  }

  .greetingMiniline,
  .greetingMiniline-static,
  .greetingMiniline span,
  .greetingMiniline div {
    font-size: 18px;
    margin-top: 12px;
  
    width: 100%;
    text-align: center !important;
  }

  .greetingHeadline,
  .greetingHeadline-static,
  .greetingHeadline div,
  .greetingHeadline span {
    font-size: 40px !important;
    width: 100% !important;
    margin: 12px 0 24px 0;
   
    white-space: normal;
    text-align: center !important;
  }

  .hrLine {
    width: 80%;
    max-width: 300px;
  }

  .avatar-selection {
    width: 100%;
    padding: 0 16px;
  }

  .intro-content-container {
    width: 100%;
    padding: 0;
    text-align: center;
  }
}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .greetingHeadline,
  .greetingHeadline-static,
  .greetingHeadline div,
  .greetingHeadline span {
    font-size: 42px !important;
  }
.h3 {text-align: left !important;}
  .greetingMiniline,
  .greetingMiniline-static {
    font-size: 16px;
  }
  .portfolio-nav.expanded {
    width: 280px;
    background-color: #000 !important;
  }
  .portfolio-nav.light-mode.expanded {

    background-color: #ffffff !important;
  }
  .profile-pic {
    width: 80px;
    height: 80px;
  }

  .timeline-line {
    display: none;
  } 
  .timeline {

    width:90% !important;
    padding-left:0px !important;
    padding-right:0px !important; 
  }

  .timeline-static{

    width:90% !important;
    padding-left:0px !important;
    padding-right:0px !important; 
  }
  .timeline-item:hover .timeline-content {
    background: rgba(0, 0, 0, 0.9) !important;
    backdrop-filter: blur(10px) !important;
}

.timeline-year{
  text-align:left !important;
  margin-left:66px !important;
}
.timeline-detail-description{
font-size:18px !important;
}
  .timeline-item{
    width:100% !important;
    left:0px !important;
    padding-left:0px !important;
    padding-right:0px !important;


  }

.timeline-logo{
left:0px !important;
position:absolute !important;
top:-62px !important;
}

  .timeline-content  {
left:0px !important;
margin:24px !important;
padding:16px !important;

position:absolute;
width:100% !important;
  }



  .timeline-content p{
    text-align: left !important;
    font-size:18px !important;
    font-weight: 400 !important;
  }
  .timeline-content h3{
    text-align: left !important;
  }


.nav-item.light-mode {
 
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgb(255, 255, 255) !important;
  border:1px solid #838383 !important;

}



}

/* Ensure content doesn't overflow on very small devices */
@media (max-width: 320px) {
  .home-container {
    padding: 12px;
  }

  .greetingHeadline,
  .greetingHeadline-static,
  .greetingHeadline div,
  .greetingHeadline span {
    font-size: 28px !important;
  }
}

.intro-content-container {
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

/* Timeline base styles */
.timeline,
.timeline-static {
  position: relative;
  width: 100%;
  max-width: 800px;
  padding: 40px 20px;
  opacity: 1;
  margin-top: 50px;
  perspective: 1000px;
  will-change: transform, opacity;
}

.timeline-item {
  width: calc(100% - 150px);
  min-height: 100px;
  position: relative;
  left: 50px;
  opacity: 0;
  transform: translateY(20px);
  margin-bottom: 20px;
  transform-style: preserve-3d;
  transition: transform 0.3s ease;
  animation: fadeIn 0.3s ease forwards;
  animation-delay: 2.4s;
  z-index: 2;
}

.timeline-logo {
  position: relative;
  margin-left: 24px;
  top: 50px;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  padding: 6px;
  background: #121212;
  box-shadow: 0 2px 8px rgba(75, 75, 75, 0.4);
  z-index: 2;
}

/* Mobile Timeline Styles */
@media (max-width: 768px) {
  .timeline,
  .timeline-static {
    padding: 0;
    margin-top: 30px;
    width: 100vw;
    max-width: 100%;

  }

  .timeline-item {
    width: 100%;
    left: 0;
    padding: 0 16px;
    box-sizing: border-box;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .timeline-logo {
    position: static;
    margin: 0;
    margin-bottom: 12px;
    transform: none;
    top: 0;
    order: 1;
  }

  .timeline-year {
    margin: 0;
    margin-bottom: 16px;
    text-align: center;
    order: 2;
    width: 100%;
  }

  .timeline-content {
    padding: 16px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    order: 3;
    width: calc(100% - 32px);
    margin: 0 16px;
  }

  .timeline-line {
    display: none;
  }

  .timeline-content h3 {
    text-align: center;
    margin: 8px 0;
  }

  .timeline-content p {
    text-align: center;
    margin: 8px 0;
  }

  .timeline-detail {
    margin-top: 16px;
  }

  .timeline-detail-image,
  .timeline-detail-video {
    width: 100%;
    margin: 16px 0;
  }

  .timeline-view-button {
    width: 100%;
    margin-top: 16px;
  }
}

/* Ensure content is properly spaced on very small devices */
@media (max-width: 480px) {
  .timeline-item {
    margin-bottom: 24px;
  }


}