/* src/components/CaseStudyThumbnail.css */

.case-study-thumbnail {
  cursor: pointer;
  transition: width 0.3s 0.3s, transform 0.5s 0.5s, opacity 0.5s 0.5s,
    box-shadow 0.5s 0.5s;
  animation: slideUpFadeIn 0.5s ease-out forwards;
  opacity: 0; /* Ensure elements start hidden */
  height: 400px;
  width: 350px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    /* small shadow */ 0 10px 20px rgba(0, 0, 0, 0.2),
    /* medium shadow */ 0 30px 60px rgba(0, 0, 0, 0.3); /* large shadow */
}

.case-study-thumbnail:hover {
  width: 500px;
  opacity: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
    /* small shadow */ 0 20px 40px rgba(0, 0, 0, 0.2),
    /* medium shadow */ 0 40px 80px rgba(0, 0, 0, 0.3); /* large shadow */
}

.case-study-thumbnail h3 {
  font-size: 30px;
}

.case-study-details {
  text-align: center;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  z-index: 2;
  padding: 0 10px;
  transition: opacity 0.5s;
}

.case-study-details p {
  opacity: 0;
  transition: opacity 1s 0.3s;
  width: 80%;
}

.case-study-thumbnail:hover .case-study-details p {
  opacity: 1;
}

.case-study-thumbnail::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  transition: all 0.5s;
  z-index: 1;
}

.case-study-thumbnail:hover::before {
  background-color: rgba(0, 0, 0, 0.3);
}

@keyframes slideUpFadeIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
