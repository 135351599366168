.whitepaper-container {
  display: flex;
  min-height: 100vh;
  background-color: #ffffff;
  transition: padding-left 0.3s ease;
  scroll-behavior: smooth;
}

.whitepaper-container.nav-expanded {
  padding-left: 240px;
}

.whitepaper-content {
  flex: 1;
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  font-family: 'Times New Roman', Times, serif;
  line-height: 1.6;
  color: #333;
}

.whitepaper-header {
  margin-bottom: 40px;
  text-align: center;
  border-bottom: 2px solid #333;
  padding-bottom: 30px;
}

.whitepaper-header h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: bold;
}

.abstract {
  margin: 30px 0;
  font-style: italic;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
}

.abstract h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  font-weight: bold;
}

.whitepaper-section {
  margin-bottom: 40px;
}

.whitepaper-section h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: bold;
}

.whitepaper-section p {
  margin-bottom: 20px;
  text-align: justify;
}

.whitepaper-section ul {
  margin: 20px 0;
  padding-left: 40px;
}

.whitepaper-section ul li {
  margin-bottom: 10px;
  line-height: 1.6;
}

.references {
  font-size: 0.9em;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.references li {
  margin-bottom: 8px;
  font-style: italic;
}

@media (max-width: 768px) {
  .whitepaper-content {
    padding: 20px;
  }

  .whitepaper-header h1 {
    font-size: 2em;
  }

  .whitepaper-section h2 {
    font-size: 1.5em;
  }
} 