.project-container {
  display: flex;
  min-height: 100vh;
  background-color: transparent;
  color: white;
  scroll-behavior: auto;
  transition: padding-right 0.3s ease;
  padding-right: 0;
  position: relative;
  overflow: hidden;
}

.project-container.chat-expanded {
  padding-right: 452px; /* 420px (panel width) + 32px (panel margins) */
}

.project-container.nav-collapsed {
  padding-left: 0;
}

.project-content {
  flex: 1;
  overflow-y: auto;
  scroll-behavior: auto;
  overflow-x: hidden;
  transition: opacity 0.3s ease;
  opacity: 1;
  min-height: 100vh;
  position: relative;
  background-color: inherit;
}

.project-content.transitioning {
  opacity: 0;
  pointer-events: none;
}

/* Remove transform from transitioning state */
.project-content.transitioning * {
  visibility: hidden;
}

/* Remove smooth scrolling from document */
html {
  scroll-behavior: auto;
}

/* Adjust content padding when nav is collapsed */
.project-container.nav-collapsed .project-content {
  transition: opacity 0.3s ease, padding-left 0.3s ease;
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateX(5px);
}

.nav-item.active {
  background-color: rgba(255, 255, 255, 0.15);
  border-left: 3px solid #8B5CF6; /* Changed to purple */
}

/* Remove the bottom border animation */
.nav-item::after {
  display: none;
}

/* Password Protection Styles */
.project-content .password-protection {
  position: fixed;
  left: 280px;
  transition: left 0.3s ease;
}

.nav-collapsed .project-content .password-protection {
  left: 80px;
}

/* Ensure the background persists during transition */
.case-study {
  min-height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .project-container {
    padding-right: 0;
  }

  .project-container.chat-expanded {
    padding-right: 0;
  }

  .project-content {
    padding: 0;
    width: 100%;
  }

  .project-content .password-protection {
    left: 0;
    width: 100%;
    padding: 20px;
  }

  .nav-collapsed .project-content .password-protection {
    left: 0;
  }
}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .project-container {
    padding: 0;
  }

  .project-content {
    padding: 0;
  }

  .project-content .password-protection {
    padding: 16px;
  }
} 